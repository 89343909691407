import React from 'react';
import { graphql, Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Footer from '../components/footer';
import SocialShare from '../components/socialShare';
import landingPageBg from '../../static/landing-page-bg.jpg';


const About = ({ location, data }) => {

  const aboutContent = `This interactive historical timeline complements the series <em>British Columbia: An Untold History</em>, a four-part documentary series offering an inclusive and diverse perspective as told from those who have lived and studied B.C.’s shared past. The timeline runs in tandem with the series and is a source of additional and related material to the history of British Columbia and the west coast seen through a multi-narrative, pluralistic and racialized lens. Designed as a supportive tool, the timeline was conceived to represent the diverse stories and communities in all geographical regions throughout BC across the 200 years of history that the documentary series covers.`;

  const dataOptions = {
    url: location.href,
    title: `About - ${data.site.siteMetadata.title}`,
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <Layout classContainer="landing-page-bg" bgContainer={landingPageBg}>
      <SEO
        title={dataOptions.title}
        url={dataOptions.url}
      />
      <div id="about-info-wrapper" className="main-fixed-wrapper">
        <Container fluid="lg">
          <Row className="mb-2 mb-md-5">
            <Col>
              <button
                type="button"
                onClick={goBack}
                className="back-link  mb-5 mb-md-0"
              >
                <span className="explore-arrow position-relative">
                  <span className="long-arrow left-arrow" />
                  <span className="label">Go Back</span>
                </span>
              </button>
            </Col>
          </Row>
          <Row className="reveal-animate justify-content-center content-row align-items-center">
            <Col lg={8} md={12}>
              <h2 className="about-heading reveal-animate mb-4 mb-md-5 text-center">About</h2>

              <div id="about-content" className="mb-0 mb-md-5" dangerouslySetInnerHTML={{ __html: `<p class="text-justify">${aboutContent}</p>` }}></div>
            </Col>
          </Row>
        </Container>
      </div>
      <SocialShare dataOptions={dataOptions} />
      <Footer>
        <div id="footer-color" />
      </Footer>
    </Layout>
  );
}

export default About;

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
